<template>
	<div class="wrapper">
		<div class="main-section">
			<div class="content">
				<!-- <div class="auchor">
					<img class="avatar" :src="match.face || '0'" @error="onAvatarError" />
					<div class="info">
						<div class="title" v-if="match.match_time_int">
							<span class="league">{{ match.league_name_zh || match.league_name }}</span>
							<span class="time">{{ match.match_time || match.start_time }}</span>
						</div>
						<div class="meta">
							<span class="nickname">{{ match.nickname || match.username }}</span>
							<span style="flex:1"></span>
						</div>
					</div>
					<span class="team" >
						<span class="teamname" style="text-align: right;">{{ match.home_team_zh || match.home_name_zh }}</span>
						<img :src="match.home_logo || '0'" @error="onTeamLogoError($event, match.type)" />
						<span style="color:#ED4B65">VS</span>
						<img :src="match.away_logo || '0'" @error="onTeamLogoError($event, match.type)" />
						<span class="teamname">{{ match.away_team_zh  || match.away_name_zh }}</span>
					</span>
					<div class="extrainfo" v-if="minfo.uid != match.uid">
						<a :class="['follow', match.is_follow == 1 ? 'active' : '']" href="javascript:;"
							@click="onFollow">关注</a>
						<a-popover placement="bottom" v-if="!!match.qrcode">
							<template slot="content">
								<div class="popover-qrcode">
									<img :src="match.qrcode" />
									<span>
										扫一扫
										<br />
										加主播好友
									</span>
								</div>
							</template>
<img class="qrcode" src="../assets/img/icon-qrcode.png" />
</a-popover>
</div>
</div> -->
				<div class="card" v-if="match.league_id || match.tournament_id || match.is_game == 1">
					<div class="card-header">
						<span class="league" v-html="match.league_name_zh || match.league_name"></span>
						<span class="matchtime">{{ startTime }}</span>
					</div>
					<div class="card-body">
						<div class="teamname homename" v-html="match.home_team_zh || match.home_name_zh"></div>
						<!-- {{ match.type }} -->
						<img :src="match.home_logo" class="teamlogo"
							@error="onTeamLogoError($event, match.type || query.tournament_type)" />
						<div class="score">
							<!-- {{ match.home_score }} -->
						</div>
						<div class="time">
							<div v-if="[1, 2].includes(query.tournament_type)">
								<span v-if="match.state == 0" class="mstatus" style="color: #666">未</span>
								<span v-else-if="match.state == -1" class="mstatus">完</span>
								<span class="mstatus" v-else-if="query.tournament_type == 1">
									<span class="timeName">
										{{ match.on_time }}<span class="move-dian" v-if="match.state > 0">'</span>
									</span>
								</span>
								<span class="mstatus" v-else-if="query.tournament_type == 2">
									<span class="changci">
										{{ match.league_id == 8 ? stateNameNcaa[match.state] : stateName[match.state] }}
									</span>
									<span class="timeName"
										v-show="match.on_time && match.state >= 1 && match.state <= 7">
										{{ arrive_timer_format(match.on_time) }}
									</span>
								</span>
							</div>
							<div v-else-if="[8, 9, 10, 11].includes(query.tournament_type)">
								<span v-if="onTimeDetail.state == 1" class="mstatus" style="color: #666">未</span>
								<span v-else-if="onTimeDetail.state == 3" class="mstatus">完</span>
								<span class="mstatus" v-else>{{ onTimeDetail.on_time }}</span>
							</div>
							<div v-else>直播中</div>
						</div>
						<div class="score">
							<!-- {{ match.away_score }} -->
						</div>
						<img :src="match.away_logo" class="teamlogo"
							@error="onTeamLogoError($event, match.type || query.tournament_type)" />
						<div class="teamname" v-html="match.away_team_zh || match.away_name_zh"></div>
					</div>
				</div>

				<div v-if="(match.tv_id && state == 1) || (query.member_id && query.member_id != 'eu') || query.uid"
					class="player-wrapper"><ws-player ref="player" /></div>
				<div class="holder" v-else>
					<div class="info-wrap">
						<div class="info-header">
							<p>{{ match.league_name_zh || match.league_name }}</p>

							<p>{{ startTime }}</p>
						</div>
						<div class="info-state">
							<div v-if="query.tournament_type == 1 || query.tournament_type == 2">
								<span v-if="match.state == 0" class="mstatus" style="color: #666">未</span>
								<span v-else-if="match.state == -1" class="mstatus">完</span>
								<span v-else-if="match.state == -14" class="mstatus">推迟</span>
								<span class="mstatus" v-else-if="query.tournament_type == 1">
									<span class="timeName">{{ match.on_time }}</span>
									<span class="move-dian" v-if="match.state > 0">'</span>
								</span>
								<span class="mstatus" v-else-if="query.tournament_type == 2">

									<span class="changci">
										{{
					match.league_id == 8
						? stateNameNcaa[match.state]
						: stateName[match.state]
				}}
									</span>
									<span class="timeName" v-show="match.on_time && match.state >= 1 && match.state <= 7
					">
										{{ arrive_timer_format(match.on_time) }}
									</span>
								</span>
							</div>
							<div v-else-if="query.tournament_type == 8 ||
					query.tournament_type == 9 ||
					query.tournament_type == 10 ||
					query.tournament_type == 11
					">
								<span v-if="match.state == 1" class="mstatus" style="color: #666">未</span>
								<span v-else-if="match.state == 3" class="mstatus">完</span>
								<span class="mstatus" v-else>{{ match.on_time }}</span>
							</div>
							<div v-else>直播中</div>
						</div>
						<div class="info-score">
							<div class="home-info">
								<span class="name">{{
					match.home_team_zh || match.home_name_zh
				}}</span>
								<img :src="match.home_logo" class="team-logo"
									@error="onTeamLogoError($event, query.tournament_type)" />
							</div>
							<div class="score">
								{{ match.home_score }} - {{ match.away_score }}
							</div>
							<div class="away-info">
								<img :src="match.away_logo" class="team-logo"
									@error="onTeamLogoError($event, query.tournament_type)" />
								<span class="name">{{
					match.away_team_zh || match.away_name_zh
				}}</span>
							</div>
						</div>
						<div class="info-anchor" v-if="more.length > 0" :class="{ more: more.length > 8 }">
							<ul>
								<li v-for="(i, idx) in more" :key="`${idx}-${i.id}`">
									<a :href="`/detail/${query.tournament_type}/${i.tournament_id}/${i.member_id}`
					" target="_blank">
										<div class="anchor-img">
											<img :src="i.face" alt="" />
										</div>
										<p>{{ i.nickname }}</p>
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div class="aside"><ws-chatroom :match="match" :anchor_uid="match.uid" ref="chatroom"
					@chat="toggleChat"></ws-chatroom>
			</div>
		</div>
		<div class="on-time">
			<div class="on-time-info">
				<div class="on-time-tab-wrap">
					<a-tabs class="on-time-tabs" :default-active-key="activeName" @change="handleTabClick">
						<a-tab-pane tab="实时数据" key="first">
							<component :is="onTimeComponent" :detail="onTimeDetail" :event="onTimeEvent"></component>
						</a-tab-pane>
						<a-tab-pane tab="欧洲指数" key="eu">
							<component type="eu" :is="onOddsComponent" v-if="this.odds" :odds="odds.eu"
								@filterOdds="filterOdds">
							</component>
						</a-tab-pane>
						<a-tab-pane tab="让球指数" key="asia">
							<component type="asia" :is="onOddsComponent" v-if="this.odds" :odds="odds.asia"
								@filterOdds="filterOdds">
							</component>
						</a-tab-pane>
						<a-tab-pane tab="大小指数" key="bs">
							<component type="bs" :is="onOddsComponent" v-if="this.odds" :odds="odds.bs"
								@filterOdds="filterOdds">
							</component>
						</a-tab-pane>
					</a-tabs>
					<div class="btns" v-show="activeName != 'first'">
						<div class="btn" @click="onChangeOdds('hide')">隐藏</div>
						<div class="btn" @click="onChangeOdds('show')">保留</div>
						<div class="btn" @click="onChangeOdds('all')">显示全部</div>
						<div class="text">
							共 <span class="red">{{ firmNumber }}</span>间公司
						</div>
					</div>
				</div>
			</div>
			<div class="more_live">
				<div class="live_title">
					<div class="l">预约赛程</div>
					<!-- <a class="r" href="/" target="_blank"><span>查看更多</span></a> -->
				</div>
				<div class="noevents" v-if="schedules.length == 0">暂无预约赛事</div>
				<template v-for="(items, key) in ScheduleObj">
					<div class="more_date">
						{{ key }}
					</div>
					<div class="events_more">
						<a v-for="item in items" :key="item.tournament_id" class=""
							:href="`/detail/${item.type}/${item.tournament_id}`" target="_blank">
							<div class="events_bottom">
								<div class="home_name name">{{ item.home_team_zh }}</div>
								<div class="logo">
									<img :src="item.home_logo" width="24px"
										@error="onTeamLogoError($event, item.type)" />
								</div>
								<div class="vs">
									<span style="color: rgba(0,0,0,0.6);">{{
					item.start_time
				}}</span>
									<span>vs</span>
								</div>
								<div class="logo">
									<img :src="item.away_logo" width="24px"
										@error="onTeamLogoError($event, item.type)" />
								</div>
								<div class="away_name name">{{ item.away_team_zh }}</div>
							</div>
						</a>
					</div>
				</template>
			</div>
		</div>
		<!-- <div class="section" v-if="schedules.length > 0">
			<div class="section-header">主播日程</div>
			<div class="section-swiper">
				<div class="schedules">
					<div class="swiper-container">
						<div class="swiper-wrapper">
							<div class="swiper-slide schedule-item" v-for="(item, index) in schedules" :key="index">
								<div class="meta">
									<img v-if="item.type == 1" src="../assets/img/icon-schedule-1.png" />
									<img v-else-if="item.type == 2" src="../assets/img/icon-schedule-2.png" />
									<span class="league">{{ item.league_name_zh }}</span>
									{{ ((item.match_time_int || item.start_time_int) * 1000) | dateFormat('MM-DD HH:mm')
									}}
								</div>
								<div class="teamname">
									<img :src="item.home_logo"
										@error="onTeamLogoError($event, item.tournament_type || item.type)" />
									<span>{{ item.home_team_zh }}</span>
								</div>
								<div class="teamname">
									<img :src="item.away_logo"
										@error="onTeamLogoError($event, item.tournament_type || item.type)" />
									<span>{{ item.away_team_zh }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div> -->

		<div class="section" v-if="otherAnchors.length > 0">
			<div class="section-header">视频推介</div>
			<div class="section-body"><ws-match-item v-for="(item, index) in otherAnchors" :key="index"
					:item="item"></ws-match-item></div>
		</div>

		<div class="tips">
			特别声明：
			<br />
			360吧是一个综合性体育赛事直播平台，我们所有的赛事直播源均由第三方提供，我们不提供任何独家赛事直播视频，不制作任何赛事直播数据。
			<br />
			请各位网友遵守当地国家法律法规，请勿用于非法用途，如有疑问请与我们取得联系。
		</div>

		<transition name="slide">
			<div class="ws-chat" v-if="temporaryToken">
				<div class="chat-header">
					<span class="title blink" v-if="hasNewMessage"> 【您有新的消息】</span>
					<span class="title" v-else> {{ match.nickname || match.username }}</span>
					<span @click="toggleChat"> {{ showChat ? '收起' : '展开' }} </span>
				</div>
				<div class="chat-body" v-show="showChat">
					<ws-chat ref="chat" :uid="match.uid" :avatar="match.face" :token="temporaryToken"></ws-chat>
				</div>
			</div>
		</transition>
		<ws-fixed-bottom></ws-fixed-bottom>
		<wsDownloadPopup v-if="codeShow"  @close="closeDownloadModal()"/>
	</div>
</template>

<script>
import wsPlayer from '@/components/wsPlayer.vue';
import wsMatchItem from '@/components/wsMatchItem.vue';
import wsChatroom from '@/components/wsChatroom.vue';
import wsChat from '@/components/wsChat.vue';
import { follow } from '@/utils/fn.js';
import { mapState } from 'vuex';
import dayjs from "dayjs";
import FootballOnTime from "@/components/score/footballOnTime";
import FootballOdds from "@/components/score/footballOdds";
import backetballOdds from "@/components/score/backetballOdds";
import BasketballOnTime from "@/components/score/basketballOnTime";
import wsFixedBottom from '@/components/wsFixedBottom.vue';
import wsDownloadPopup from '@/components/wsDownloadPopup.vue';
import wsService from '@/utils/websocket.js'

const stateName = {
	0: "未开赛",
	1: "第一节",
	2: "第二节",
	3: "第三节",
	4: "第四节",
	5: "加时",
	6: "加时",
	7: "加时",
	"-1": "完场",
	"-2": "待定",
	"-3": "中断",
	"-4": "取消",
	"-5": "推迟",
	50: "中场",
};

const stateNameNcaa = {
	0: "未开赛",
	1: "上半场",
	3: "下半场",
	5: "加时",
	6: "加时",
	7: "加时",
	"-1": "完场",
	"-2": "待定",
	"-3": "中断",
	"-4": "取消",
	"-5": "推迟",
	50: "中场",
};
export default {
	name: 'Home',
	components: {
		wsMatchItem,
		wsPlayer,
		wsChatroom,
		wsChat,
		FootballOnTime,
		BasketballOnTime,
		FootballOdds,
		wsFixedBottom,
		wsDownloadPopup
	},
	data() {
		return {
			match: {},
			more: [],
			schedules: [],
			recommends: [],
			showChat: false,
			hasNewMessage: false,

			showDownloader: false,
			isConfirm: false,
			activeName: "first",
			onTimeDetail: {},
			onTimeEvent: [],
			allOdds: { asia: [], bs: [], eu: [] },
			selectedData: [],
			dataWs: null,
			baseDetail: { tv_id: 0 },
			stateName,
			query: {
				tournament_type: 0,
				tournament_id: "",
				member_id: "",
				anchor_id: "",
			},
			codeShow:false,
		};
	},
	computed: {
		...mapState(['token', 'minfo', 'temporaryToken', 'temporaryUser', 'temporaryChat']),
		onTimeComponent() {
			if (this.query.tournament_type == 1) {
				return FootballOnTime;
			}
			if (this.query.tournament_type == 2) {
				return BasketballOnTime;
			}
			return null;
		},
		detail() {
			let detail = this.baseDetail;
			if (this.anchorDetail.room_id) {
				detail = this.anchorDetail;
			}
			return detail;
		},
		onOddsComponent() {
			if (this.query.tournament_type == 1) {
				return FootballOdds;
			}
			if (this.query.tournament_type == 2) {
				return backetballOdds;
			}
			return null;
		},
		onTimeComponent() {
			if (this.query.tournament_type == 1) {
				return FootballOnTime;
			}
			if (this.query.tournament_type == 2) {
				return BasketballOnTime;
			}
			return null;
		},
		onOddsComponent() {
			if (this.query.tournament_type == 1) {
				return FootballOdds;
			}
			if (this.query.tournament_type == 2) {
				return backetballOdds;
			}
			return null;
		},
		otherAnchors() {
			if (this.query.member_id && this.query.member_id != "eu") {
				if (this.more.length && this.match.tv_id) {
					return (
						this.more.filter((v) => v.tv_id != this.match.tv_id) || []
					);
				} else {
					return [];
				}
			} else {
				if (this.more.length) {
					return this.more;
				} else {
					return [];
				}
			}
		},
		isConfirmMember() {
			return true; // this.confirmMemberIds.includes(this.anchorDetail.member_id)
		},
		etail() {
			let detail = this.baseDetail;
			if (this.anchorDetail.room_id) {
				detail = this.anchorDetail;
			}
			return detail;
		},
		state() {
			// -1 已结束 0 未开始 1 进行中
			let state = 0;
			if (this.match.type == 1) {
				if (this.match.state == 0) {
					state = 0;
				} else if (this.match.state < 0) {
					state = -1;
				} else if (this.match.state >= 1 && this.match.state <= 7) {
					state = 1;
				}
			}
			if (this.match.type == 2) {
				if (this.match.state == 0) {
					state = 0;
				} else if (this.match.state < 0) {
					state = -1;
				} else if (this.match.state >= 1) {
					state = 1;
				}
			}
			return state;
		},
		startTime() {
			return dayjs(
				(this.match.start_time_int || this.match.match_time_int) * 1000
			).format("YYYY-MM-DD HH:mm");
		},
		firmNumber() {
			if (this.activeName == "eu") {
				return this.allOdds.eu.length;
			}
			if (this.activeName == "asia") {
				return this.allOdds.asia.length;
			}
			if (this.activeName == "bs") {
				return this.allOdds.bs.length;
			}
		},
		odds() {
			const filteredData = {};
			Object.keys(this.allOdds).forEach((key) => {
				filteredData[key] = this.allOdds[key].filter((item) => item.isShow);
			});
			return filteredData;
		},
		ScheduleObj() {
			const result = this.schedules.reduce((acc, item) => {
				let key = dayjs(item.start_time_int * 1000).format("MM月DD日");
				if (!acc[key]) {
					// 如果不存在，创建一个数组并关联到时间键
					acc[key] = [];
				}
				acc[key].push(item);
				return acc;
			}, {});
			return result;
		},
	},
	// beforeRouteUpdate(to, from, next) {
	// 	this.$loading();
	// 	this.getLiveInfo(to.params);
	// 	next();
	// },
	// beforeRouteEnter(to, from, next) {
	// 	next(vm => {
	// 		vm.$loading();
	// 		vm.getLiveInfo(to.params);
	// 	});
	// },
	created() {
		if (this.token) {
		  const currentDate = new Date().toISOString().split('T')[0];
          const lastVisitDate = localStorage.getItem('lastVisitDate');
          if (!lastVisitDate || lastVisitDate !== currentDate) {
            setTimeout( () => {
              this.codeShow = true;
            },30000)
            localStorage.setItem('lastVisitDate', currentDate);
          }
		}else {
			this.codeShow = true
		}


		this.$bus.$on('ws.message', this.onMessage);
		if (this.$route.params.tournament_id || this.$route.params.uid) {
			Object.assign(this.query, this.$route.params);
			if (this.$route.params.member_id || this.$route.params.uid) {
				this.getLiveInfo(this.$route.params);
			}
			if (this.$route.params.member_id == "eu") {
				this.activeName = "eu";
			}
			if (this.$route.params.tournament_type == 1 || this.$route.params.tournament_type == 2) {
				this.getBaseAnchor();
			}
			this.createWs();
			if (this.$route.params.member_id && this.$route.params.member_id !== "eu" || this.$route.params.uid) {
				this.getAnchorSchedule(this.$route.params.member_id);
			} else {
				this.getAnchorSchedule(133);
			}
		} else {
			location.href = "/";
		}
	},
	beforeDestroy() {
		this.$bus.$off('ws.message', this.onMessage);
		this.dataWs = null;
		this.dataWs.close();
	},
	methods: {
		closeDownloadModal(){
			this.codeShow = false
		},
		handleTabClick(key) {
			this.activeName = key
		},
		async getLiveInfo(route) {
			const { tournament_type, tournament_id, member_id, uid } = route;

			var response = null;
			if (uid) {
				response = await this.$api.get('/web/anchor/live/detail?uid=' + uid);
			} else if (tournament_id && member_id != "eu") {
				response = await this.$api.get(`/web/live_lists/${tournament_type}/detail/${tournament_id}?member_id=${member_id}`);
			}
			if (response && response.code == 200) {
				const { more, detail = {} } = response.data;
				detail.code = detail.username;
				detail.username = detail.nickname;
				detail.is_follow = detail.is_follow || 0;
				if (tournament_id) {
					detail.tournament_id = tournament_id
				}

				this.match = detail;
				this.more = (more || [])
					.filter(v => v.member_id != detail.member_id)
					.map(item => {
						return this.makeMatchItem(Object.assign({}, detail, item));
					});

				if (uid) {
					this.query.tournament_type = detail.type
					this.query.tournament_id = detail.tournament_id
					this.getBaseAnchor()
				}
				// if (tournament_id && detail.state == -1) {
				// 	const res = await this.$api.get('/web/anchor/live/detail?uid=' + detail.uid);
				// 	this.$refs.player.initPlayer(res.data.detail);
				// } else {
				// 	this.$refs.player.initPlayer(detail);
				// }
				this.$refs.player.initPlayer(detail);
				this.$nextTick(() => {
					detail.room_status == 1 && detail.roomid && this.$refs.chatroom.init(detail.roomid);
				})
				if (member_id || detail.member_id) {
					this.getAnchorSchedule(member_id || detail.member_id);
				}

				if (!this.token) {
					this.codeShow = true
					await this.$store.dispatch('clearTemporaryToken')
					let anchors = this.$storage.get('temporary_anchors') || []
					if (anchors.includes(detail.uid)) {
						await this.$store.dispatch('initTemporary', detail.uid)
						this.$connect.subscribe_presence(this.temporaryUser.uid)
						let m = await this.$store.dispatch('getTemporaryMessages', { limit: 5 })
						if (m.length > 0) {
							if (m[m.length - 1].from == this.temporaryChat.uid) {
								this.hasNewMessage = true
							}
						}
					}
				}
			}

			this.getLives();
			this.$nextTick(() => {
				this.$loadend();
			});
		},
		async getAnchorSchedule(member_id) {
			const response = await this.$api.get(`/web/anchor_schedule`, {
				params: { member_id }
			});
			if (response && response.code == 200) {
				this.schedules = response.data;

			}
		},
		async onFollow() {
			if (this.match.is_follow == 1) {
				this.$modal.confirm({
					title: '取消关注后，将不会收到主播开播提醒，确定取消吗？',
					okType: 'danger',
					okText: '取消关注',
					cancelText: '我再想想',
					onOk: () => {
						follow(this.match);
					}
				});
			} else {
				follow(this.match);
			}
		},
		async getLives() {
			const response = await this.$api.get(`/web/live_lists/1`);
			if (response && response.code == 200) {
				this.recommends = response.data.data.map(this.makeMatchItem);
			}
			this.$nextTick(() => {
				this.$loadend();
			});
		},
		onMessage(res) {
			if (res.event == `roomMatch-${this.match.uid}` && res.channel == `room`) {
				console.info('match', this.match, res)
				if (this.match.tournament_id != res.data.tournament_id) {
					if (this.$route.params.tournament_id) {
						history.replaceState(null, null, `/detail/${res.data.type}/${res.data.tournament_id}/${res.data.member_id}`)
					}
					this.getLiveInfo({
						tournament_type: res.data.type,
						tournament_id: res.data.tournament_id,
						member_id: res.data.member_id
					})
				}
			}
		},
		toggleChat() {
			if (this.showChat) {
				this.showChat = false
			} else {
				this.initChat()
			}
			this.hasNewMessage = false
		},
		async initChat() {
			this.hasNewMessage = false
			this.showChat = true
			if (this.minfo.uid) {
				this.$modal
					.confirm({
						content: '下载APP，私信主播，享受更多体验',
						okText: '下载APP',
						onOk() {
							window.open('/app');
						}
					});
				return
			} else if (!this.temporaryToken) {
				let { uid } = this.match
				let randomKey = this.$storage.get('randomKey') || false
				if (!randomKey) {
					randomKey = Math.random().toString(16).slice(2)
					this.$storage.set('randomKey', randomKey)
				}
				await this.$store.dispatch('initTemporary', uid)
				this.$connect.subscribe_presence(this.temporaryUser.uid)
			}

			if (window.Notification && Notification.permission != 'granted') {
				await Notification.requestPermission()
			}
			if (this.$refs.chat) {
				this.$nextTick(() => {
					this.$refs.chat.scrollBottom()
				})
			}
		},
		async getBaseAnchor(id, t) {
			const { tournament_id, tournament_type } = this.query;
			let res = null;
			res = await this.$api.get(
				`/web/get_public_anchor?tournament_id=${tournament_id}&type=${tournament_type}`
			);
			if (res.code == 200) {
				const { data: detail, more } = res.data || {};
				this.baseDetail = detail;
				this.isConfirm = true
				this.more = (more || []).filter(v => v.member_id != detail.member_id).map((v) => {
					v.href = `/detail/${tournament_type}/${v.tournament_id}/${v.member_id}`;
					v.title = `${detail.league_name} ${detail.home_name_zh} VS ${detail.away_name_zh}`;
					return v;
				});
				// console.log(this.$route.params.member_id);
				if ((!this.$route.params.member_id && !this.$route.params.uid) || this.$route.params.member_id == "eu") {
					// console.log(this.$route.params.uid);
					this.match = res.data.data
					this.$refs.chatroom.init(res.data.data.room_id);
					this.recommends = this.more
				}
				// this.$refs.chatroom.init(res.data.data.room_id);
				this.getOnTimeData(detail.tournament_id);
				this.getOddsData(detail.tournament_id);
			}
		},
		getOnTimeData(id) {
			const type = this.query.tournament_type;
			const game = type == 1 ? "get_football_text" : "get_basketball_text";
			let url = `/web/${game}?tournament_id=${id}`;
			if (type == 1) {
				// url = `${url}&v=1`;
			}
			this.$api.get(url).then((res) => {
				if (res.code == 200) {
					this.onTimeDetail = Object.assign(res.data, {
						even: res.data.even || [],
						tlive: res.data.tlive || [],
						home_shoot: res.data.home_shoot_positive + res.data.home_deflection,
						away_shoot: res.data.away_shoot_positive + res.data.away_deflection,
					});
					if (type == 1) {
						this.onTimeEvent = res.data.even || [];
					}
					if (type == 2) {
						this.onTimeEvent = res.data.tlive || [];
					}
				}
			});
		},
		getOddsData(id) {
			const type = this.query.tournament_type;
			const game = type == 1 ? "football_odds" : "basketball_odds";
			let url = `/web/${game}?tournament_id=${id}`;
			this.$api.get(url).then((res) => {
				if (res.code == 200) {
					for (const key in res.data) {
						if (res.data.hasOwnProperty(key)) {
							res.data[key].forEach((item) => {
								item.isShow = true;
							});
						}
					}
					this.allOdds = res.data;
				}
			});
		},
		filterOdds(val) {
			this.selectedData = val;
		},
		onChangeOdds(type) {
			if (type == "all") {
				this.allOdds[this.activeName].forEach((item) => {
					item.isShow = true;
				});
			}
			if (type == "hide") {
				if (this.selectedData.length <= 0) {
					return;
				}
				this.allOdds[this.activeName] = this.allOdds[this.activeName].map(
					(item1) => {
						const matchingItem = this.selectedData.find(
							(item2) => item2.company_id == item1.company_id
						);
						if (matchingItem) {
							return { ...item1, isShow: false };
						} else {
							return { ...item1 };
						}
					}
				);
			}
			if (type == "show") {
				if (this.selectedData.length <= 0) {
					return;
				}
				this.allOdds[this.activeName] = this.allOdds[this.activeName].map(
					(item1) => {
						const matchingItem = this.selectedData.find(
							(item2) => item2.company_id == item1.company_id
						);
						if (matchingItem) {
							return { ...item1, isShow: true };
						} else {
							return { ...item1, isShow: false };
						}
					}
				);
			}
		},
		arrive_timer_format(s) {
			const minutes = String(Math.floor(s / 60)).padStart(2, "0");
			const seconds = String(s % 60).padStart(2, "0");
			return `${minutes}:${seconds}`;
		},
		createWs() {
			const type = this.query.tournament_type;
			this.dataWs = new wsService(this.wsUrl, 5000, 3);
			this.dataWs.init();
			let task_id = type == 1 ? "app_fmatches_" + this.query.tournament_id : "app_bmatches_" + this.query.tournament_id;
			this.dataWs.onOpen(() => {
				this.dataWs.sendMessage({ type: "init", task_id: task_id });
			});
			this.dataWs.onMessage = this.receiveMsg;
		},
		receiveMsg(msg) {
			if (
				msg.type == "matches" ||
				msg.type == "btechnicals" ||
				msg.type == "ftechnicals"
			) {
				//处理篮球足球字段不一样的
				if (msg.result.home_score) {
					msg.result.home_total_score = msg.result.home_score;
				}
				if (msg.result.away_score) {
					msg.result.away_total_score = msg.result.away_score;
				}
				Object.assign(this.onTimeDetail, msg.result);
				Object.assign(this.baseDetail, msg.result);
				Object.assign(this.match, msg.result);
				if (msg.type == "ftechnicals") {
					//射偏数据需要单独处理一下
					this.onTimeDetail.home_deflection =
						this.onTimeDetail.home_shoot -
						this.onTimeDetail.home_shoot_positive;
					this.onTimeDetail.away_deflection =
						this.onTimeDetail.away_shoot -
						this.onTimeDetail.away_shoot_positive;
					if (this.onTimeDetail.home_shoot_positive < 0) {
						this.onTimeDetail.home_shoot_positive = 0;
					}
					if (this.onTimeDetail.home_deflection < 0) {
						this.onTimeDetail.home_deflection = 0;
					}
					if (this.onTimeDetail.away_shoot_positive < 0) {
						this.onTimeDetail.away_shoot_positive = 0;
					}
					if (this.onTimeDetail.away_deflection < 0) {
						this.onTimeDetail.away_deflection = 0;
					}
				}
			}
			if (msg.type == "btlive") {
				this.onTimeEvent.push(msg.result);
			}
			if (msg.type == "text_live") {
				this.onTimeEvent.unshift(msg.result);
			}
			if (msg.type == "odds") {
				Object.keys(msg.result).forEach((key) => {
					this.allOdds[key].forEach((item) => {
						if (item.company_id == msg.result[key].company_id) {
							if (item.e > msg.result[key].e) {
								msg.result[key].eclass = "up";
							} else if (item.e < msg.result[key].e) {
								msg.result[key].eclass = "down";
							} else {
								msg.result[key].eclass = "";
							}

							if (item.f > msg.result[key].f) {
								msg.result[key].fclass = "up";
							} else if (item.f < msg.result[key].f) {
								msg.result[key].fclass = "down";
							} else {
								msg.result[key].fclass = "";
							}

							if (msg.result[key].d == "封") {
								msg.result[key].eclass = "";
								msg.result[key].fclass = "";
							}
							Object.assign(item, msg.result[key]);
						}
					});
				});
			}
		},
	}
};
</script>

<style lang="less">
@import url('~@/assets/less/live.less');

.popover-qrcode {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 12px;
	color: #333333;
	text-align: center;

	img {
		width: 84px;
		height: 84px;
		margin-bottom: 10px;
	}
}


.ws-chat {
	position: fixed;
	bottom: 0;
	right: 0;
	z-index: 99;
	width: 320px;
	// height: 500px;
	border: #ff7a1d 1px solid;
	border-radius: 4px;
	overflow: hidden;
	// display: flex;
	// flex-direction: column;

	.chat-body {
		height: 500px;
		// flex: 1;
		overflow: hidden;
	}

	.chat-header {
		height: 44px;
		background-color: #ff7a1d;
		color: #ffffff;
		display: flex;
		align-items: center;
		padding: 0 12px;

		.title {
			flex: 1;
			font-weight: bold;
		}

		.blink {
			animation: blink 500ms linear infinite;
		}
	}
}

@keyframes blink {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

.slide-enter-active,
.slide-leave-active {
	transition: all .3s ease;
}

.slide-enter,
.slide-leave-to {
	transform: translateY(100%);
	opacity: 0;
}

.holder,
.novideo {
	position: relative;
	height: 460px;
	background: rgba(43, 43, 43, 1);
}

.on-time {
	display: flex;
}

.more_live {
	width: 300px;

	.more_date {
		color: rgba(0, 0, 0, 0.9);
		line-height: 30px;
		height: 30px;
		padding-left: 10px;
		margin-top: 10px;
		position: relative;

	}

	.more_date::before {
		content: "";
		width: 3px;
		height: 22px;
		background: rgba(255, 153, 0, 0.9);
		border-radius: 0px 100px 100px 0px;
		position: absolute;
		left: 0;
		top: 3px;
	}

	.events_more>a {
		padding: 13px 14px;
		background: #ffffff;
		color: #000000;
		display: block;
		box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.08);
		transform: scale(1);
		transition: transform 0.3s, background-color 0.3s;
		border-radius: 10px;
		margin: 10px 0;

		.events_bottom {
			display: flex;
			align-items: center;
			line-height: 24px;
			margin-top: 12px;
			text-align: center;

			.vs {
				font-size: 12px;
				display: flex;
				flex-direction: column;
			}
		}
	}

	.events_more>a:hover {
		background: linear-gradient(270deg, #0d0e0e 0%, #3e3408 52%, #15170e 100%);
		color: #ffffff;
		transform: scale(1.05);
	}
}

.on-time-info {
	margin-top: 10px;
	width: 900px;

	.on-time-tab-wrap {
		position: relative;
	}

	.btns {
		position: absolute;
		top: 0;
		right: 0;
		height: 40px;
		width: 400px;
		display: flex;
		align-items: center;

		.btn {
			padding: 0 18px;
			height: 28px;
			background: #f2f2f2;
			border-radius: 4px;
			font-size: 14px;
			color: rgba(0, 0, 0, 0.9);
			text-align: center;
			line-height: 28px;
			margin-right: 20px;
			cursor: pointer;
		}

		.text {
			.red {
				color: #df1111;
				margin: 0 8px;
			}
		}
	}
}

.card {
	margin-bottom: 10px;
	background-color: #ffffff;
	padding-bottom: 15px;
	padding-top: 4px;
	background: url(../assets/img/score/detail-score-bg.png) no-repeat;
	background-size: cover;
	background-position: center center;
	border-radius: 10px;

	&-header {
		height: 20px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		font-size: 14px;
		color: #262A50;
		gap: 30px;

		.mstatus {
			margin-left: 30px;
			color: #f43e50;
		}
	}

	&-body {
		height: 50px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		font-size: 16px;
		font-weight: bold;

		.time {
			width: 100px;
			text-align: center;
		}

		.teamname {
			color: #333333;
			// width: 320px;
			flex: 1;

			&.homename {
				text-align: right;
			}
		}

		.teamlogo {
			margin: 0 10px;
			width: 38px;
			height: 38px;
			border-radius: 50%;
		}

		.mstatus {
			color: #dd3e3b;
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		.score {
			text-align: center;
			width: 70px;
			color: #000;
			white-space: nowrap;
			font-size: 36px;
		}
	}
}

.holder {
	display: flex;
	height: 504px;
	justify-content: center;
	align-items: center;
	background: url("../assets/img/score/info-cover-bg.png") no-repeat;
	background-size: cover;
	background-position: center;

	.info-wrap {
		width: 655px;
		height: 359px;
		background: url("../assets/img/score/detail-info-bg.png") no-repeat;
		background-size: contain;
	}

	.info-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 10px 20px;
		font-size: 16px;
		color: rgba(0, 0, 0, 0.9);
	}

	.info-state {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 60px;

		>div {
			background-color: rgba(0, 0, 0, 0.4);
			color: #df1111;
			padding: 1px 5px;
		}
	}

	.info-score {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 20px;
		margin-top: 15px;

		.score {
			font-size: 18px;
			color: #df1111;
			font-weight: bold;
		}

		.home-info {
			display: flex;
			align-items: center;

			.name {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				width: 187px;
				height: 56px;
				background: linear-gradient(265deg,
						#ffcd00 0%,
						rgba(255, 205, 0, 0) 100%);
				opacity: 0.9;
				margin-right: -20px;
				padding-right: 28px;
				font-size: 18px;
				color: #fff;
			}

			img {
				width: 82px;
				height: 82px;
				display: block;
				z-index: 2;
			}
		}

		.away-info {
			display: flex;
			align-items: center;

			.name {
				display: flex;
				align-items: center;
				width: 187px;
				height: 56px;
				background: linear-gradient(90deg,
						#ffcd00 0%,
						rgba(255, 205, 0, 0) 100%);
				opacity: 0.9;
				margin-left: -20px;
				padding-left: 28px;
				font-size: 18px;
				color: #fff;
			}

			img {
				width: 82px;
				height: 82px;
				display: block;
				z-index: 2;
			}
		}
	}

	.info-anchor::-webkit-scrollbar {
		width: 5px;
		height: 5px;
		/**/
	}

	.info-anchor::-webkit-scrollbar-track {
		background: rgb(239, 239, 239);
		border-radius: 10px;
	}

	.info-anchor::-webkit-scrollbar-thumb {
		background: #bfbfbf;
		border-radius: 10px;
	}

	.info-anchor::-webkit-scrollbar-thumb:hover {
		background: #333;
	}

	.info-anchor::-webkit-scrollbar-corner {
		background: #179a16;
	}

	.info-anchor {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 585px;
		overflow: auto;
		margin: 20px auto 0;

		&.more {
			justify-content: start;
		}

		ul {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 15px;

			li {
				width: 60px;

				a {
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				.anchor-img {
					width: 38px;
					height: 38px;
					padding: 1px;
					border: 1px solid #ffcd00;
					border-radius: 50%;
					position: relative;

					&::after {
						position: absolute;
						bottom: -8px;
						left: -4px;
						content: "直播中";
						font-size: 12px;
						width: 42px;
						height: 18px;
						font-size: 12px;
						border-radius: 18px;
						background: #ffcd00;
						text-align: center;
						line-height: 18px;
						transform: scale(0.7);
					}

					img {
						display: block;
						width: 34px;
						height: 34px;
						border-radius: 50%;
					}
				}

				p {
					text-align: center;
					width: 60px;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
					color: #ffcd00;
					margin-top: 8px;
				}
			}
		}
	}
}

.more_live {
	margin-top: 10px;
	box-sizing: border-box;

	.live_title {
		overflow: hidden;

		.l {
			float: left;
			font-size: 16px;
			color: #333;
			line-height: 18px;
			margin: 16px 0 16px 14px;
		}

		.r {
			margin-right: 10px;
			float: right;
			color: #666;
			font-size: 14px;
			line-height: 49px;
			padding-left: 12px;
			padding-right: 14px;
			text-decoration: none;
			background: url(../assets/img/icon-more.png) no-repeat right center;
			background-size: 12px 12px;

			span {
				vertical-align: middle;
			}

			.iconfont {
				font-size: 12px;
				display: inline-block;
				transform: scale(0.8);
				padding-left: 3px;
			}
		}
	}

	.noevents {
		font-size: 14px;
		color: #666;
		text-align: center;
		padding: 32px 0;
	}

	.events_more>div {
		padding: 13px 20px;
	}

	.events_more>div:not(:last-child) {
		border-bottom: 1px solid #eee;
	}

	.events_more {
		.events_top {
			font-size: 12px;
		}

		.events_top>span {
			padding-right: 5px;
		}

		.events_bottom {
			display: flex;
			line-height: 24px;
			margin-top: 12px;
			text-align: center;

			img {
				width: 24px;
				height: 24px;
				border-radius: 50%;
			}

			.name {
				width: 80px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.home_name {
				margin-right: 8px;
				text-align: right;
			}

			.away_name {
				margin-left: 8px;
				text-align: left;
			}

			.vs {
				padding: 0 7px;
			}
		}

		.events_bottom>div {
			display: inline-block;
		}
	}
}
</style>
