<template>
  <div>
    <div class="on-time-wrap">
      <div class="team-info">
        <div class="home">
          <p>{{ detail.home_name_zh }}</p>
          <img
            :src="detail.home_logo"
            alt=""
            @error="onTeamLogoError($event, 2)"
          />
        </div>
        <div class="away">
          <img
            :src="detail.away_logo"
            alt=""
            @error="onTeamLogoError($event, 2)"
          />
          <p>{{ detail.away_name_zh }}</p>
        </div>
      </div>
      <div class="score-table">
        <div class="table-head">
          <div class="name">球队</div>
          <div>1节</div>
          <div>2节</div>
          <div>3节</div>
          <div>4节</div>
          <div>加时</div>
          <div>总分</div>
        </div>
        <div class="table-body">
          <div class="home">
            <div class="name">{{ detail.home_name_zh }}</div>
            <div>{{ detail.home_one_score }}</div>
            <div>{{ detail.home_two_score }}</div>
            <div>{{ detail.home_three_score }}</div>
            <div>{{ detail.home_four_score }}</div>
            <div>
              {{
                 Number(detail.home_overtime_one_score)  +
                 Number(detail.home_overtime_two_score) +
                 Number(detail.home_overtime_three_score)
              }}
            </div>
            <div class="total">{{ detail.home_total_score }}</div>
          </div>
          <div class="away">
            <div class="name">{{ detail.away_name_zh }}</div>
            <div>{{ detail.away_one_score }}</div>
            <div>{{ detail.away_two_score }}</div>
            <div>{{ detail.away_three_score }}</div>
            <div>{{ detail.away_four_score }}</div>
            <div>
              {{
                Number(detail.away_overtime_one_score) +
                Number(detail.away_overtime_two_score)+
                Number(detail.away_overtime_three_score)
              }}
            </div>
            <div class="total">{{ detail.away_total_score }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="tabs" v-if="event.length>0">
      <div :class="{active:active == i}" v-for="(item,i) in textlist" :key="i" @click="active = i">{{ i | whichSection }}</div>
    </div>
    <div class="on-time-wrap" v-if="event.length>0">
      <div class="text-info">
        <p>文字直播</p>
        <ul>
          <li v-for="(e, idx) in textlist[active]" :key="`${idx}`">
            <div>
              <span class="time">{{ e.last_time }}</span>
              <div
                class="dian"
                :class="{ home: e.text_team_id == detail.home_team_id, away: e.text_team_id == detail.away_team_id }"
              ></div>
              <span v-html="e.content"></span>
            </div>
            <div>
              <span>{{ e.home_score || 0 }}-{{ e.away_score || 0 }}</span>
              <img
                :src="detail.home_logo"
                alt=""
                @error="onTeamLogoError($event, 2)"
              />
            </div>
            <!-- <span :class="{'red': e.kind == 15}">{{e.text}}</span>
            <span v-if="[1,2,3,4,8,9,11,13,16,17].includes(Number(e.kind))">({{ e.is_home == 1 ? detail.home_name_zh : detail.away_name_zh }})</span>
            <span v-if="[1].includes(Number(e.kind))">({{ detail.home_score }} - {{ detail.away_score }})</span> -->
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
const chineseNumbers = ['一', '二', '三', '四', '五', '六', '七', '八', '九']
export default {
  name: "basketball-on-time",
  props: {
    detail: Object,
    event: Array,
  },
  data(){
	return{
		active:0,
	}
  },
  filters:{
	whichSection(num){
		if(num == 4){
			return '加时'
		}
		return `第${chineseNumbers[num]}节`
	}
  },

  computed: {
    textlist() {
           
            if (Array.isArray(this.event) && this.event.length > 0) {
                var v1 = [], v2 = [], v3 = [], v4 = [], v5 = [], result = []
                this.event.forEach(item => {
                    item.state = parseInt(item.state)
                    if (item.state == 1) {
                        v1.unshift(item)
                    } else if (item.state == 2) {
                        v2.unshift(item)
                    } else if (item.state == 3) {
                        v3.unshift(item)
                    } else if (item.state == 4) {
                        v4.unshift(item)
                    } else if (item.state == 5) {
                        v5.unshift(item)
                    } else {
                        if (v5.length > 0) {
                            v5.unshift(item)
                        } else if (v4.length > 0) {
                            v4.unshift(item)
                        } else if (v3.length > 0) {
                            v3.unshift(item)
                        } else if (v2.length > 0) {
                            v2.unshift(item)
                        } else if (v1.length > 0) {
                            v1.unshift(item)
                        }
                    }
                })
                v1.length > 0 && result.push(v1)
                v2.length > 0 && result.push(v2)
                v3.length > 0 && result.push(v3)
                v4.length > 0 && result.push(v4)
                v5.length > 0 && result.push(v5)
                return result
              }
    },
  },
  mounted(){
	if(this.textlist){
        console.log(this.textlist.length,85489894);
	     this.active = this.textlist.length
	}

  },
};
</script>

<style lang="less" scoped>
.on-time-wrap {
  padding: 30px 24px;
  .team-info {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 200px;
     p{
      margin:0;
     }
    .home,
    .away {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      position: relative;
      &:after {
        position: absolute;
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 3px;
      }

      img {
        display: block;
        width: 32px;
        height: 32px;
      }
    }
    .home {
      &:after {
        background: #df1111;
        right: -20px;
        top: 11px;
      }
    }
    .away {
      &:after {
        background: #418dff;
        left: -20px;
        top: 11px;
      }
    }
  }
  .score-table {
    width: 100%;
    margin-top: 25px;
    .table-head {
      display: flex;
      background: #efefef;
      height: 54px;
      line-height: 54px;
      color: rgba(0, 0, 0, 0.4);
      border-radius: 10px 10px 0 0;
      .name {
        width: 225px;
        padding-left: 24px;
      }
      > div:not(:first-child) {
        flex: 1;
        text-align: center;
      }
    }
    .table-body {
      > div {
        display: flex;
        height: 54px;
        line-height: 54px;
        background: #f8faff;
        .name {
          width: 225px;
          padding-left: 24px;
        }
        > div:not(:first-child) {
          flex: 1;
          text-align: center;
        }
      }
      .total {
        color: #df1111;
        font-weight: 600;
      }
    }
  }
  .text-info {
    p {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.9);
      margin-bottom: 20px;
    }
    ul {
      position: relative;
      &::after {
        position: absolute;
        content: "";
        top: 12px;
        bottom: 12px;
        left: 106px;
        width: 1px;
        border-left: 1px dashed #999;
        z-index: 1;
      }
      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        > div:first-child {
          display: flex;
          align-items: center;
          .time {
            width: 50px;
            text-align: center;
            color: #999;
          }
          .dian {
            width: 14px;
            height: 14px;
            margin: 0 10px;
            border-radius: 50%;
            background: #999;
            position: relative;
            z-index: 2;
            &.home {
              background: #df1111;
            }
            &.away {
              background: #418dff;
            }
          }
          .red {
            color: #df1111;
          }
        }
        > div:last-child {
          display: flex;
          align-items: center;
          font-weight: bold;
          font-size: 16px;
          img {
            width: 24px;
            height: 24px;
            margin-left: 60px;
          }
        }
      }
    }
  }
}
.tabs {
  display: flex;
  align-items: center;
  height: 60px;
  padding: 20px 0;
  color: rgba(0, 0, 0, 0.9);
  background: #f7f7f8;
  padding: 0 24px;
  font-weight: bold;
  font-size: 16px;
  > div {
    margin-right: 60px;
	cursor:pointer;
  }
  .active {
    color: #df1111;
  }
}
</style>
