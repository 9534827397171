<template>
  <div class="on-time-wrap">
    <div class="team-info">
      <div class="home">
        <p>{{ detail.home_name_zh }}</p>
        <img
          :src="detail.home_logo"
          alt=""
          @error="onTeamLogoError($event, 1)"
        />
      </div>
      <div class="away">
        <img
          :src="detail.away_logo"
          alt=""
          @error="onTeamLogoError($event, 1)"
        />
        <p>{{ detail.away_name_zh }}</p>
      </div>
    </div>
    <div class="range-info">
      <div class="range-item">
        <p>{{ detail.home_attack }}</p>
        <a-progress
          type="circle"
          :stroke-width="10"
          color="#418DFF"
          define-back-color="#DF1111"
          :percent="attackRange"
          :format="() => '进攻'"
        ></a-progress>
        <p>{{ detail.away_attack }}</p>
      </div>
      <div class="range-item">
        <p>{{ detail.home_danger }}</p>
        <a-progress
          type="circle"
          :stroke-width="10"
          strokeColor="#418DFF"
          define-back-color="#DF1111"
          :percent="25"
          :format="() => '危险进攻'"
        ></a-progress>
        <p>{{ detail.away_danger }}</p>
      </div>
      <div class="range-item">
        <p>{{ detail.home_ball_control }}</p>
        <a-progress
          type="circle"
          :stroke-width="10"
          color="#418DFF"
          define-back-color="#DF1111"
          :percent="detail.away_ball_control || 0"
          :format="() => '控球率 %'"
        ></a-progress>
        <p>{{ detail.away_ball_control }}</p>
      </div>
    </div>
    <div class="bar-info">
      <p>射正</p>
      <div class="bar-item">
        <div class="home">
          <img src="@/assets/img/score/corner.png" alt="" />
          <img src="@/assets/img/score/red-card.png" alt="" />
          <img src="@/assets/img/score/yellow-card.png" alt="" />
          <span>{{ detail.home_shoot_positive }}</span>
        </div>
        <div class="bar-progress">
          <a-progress
            :stroke-width="16"
            :percent="shootPositiveRange"
            :width="500"
            strokeColor="#DF1111"
            :show-text="false"
            define-back-color="#418DFF"
            :show-info="false"
          ></a-progress>
        </div>
        <div class="away">
          <span>{{ detail.away_shoot_positive }}</span>
          <img src="@/assets/img/score/yellow-card.png" alt="" />
          <img src="@/assets/img/score/red-card.png" alt="" />
          <img src="@/assets/img/score/corner.png" alt="" />
        </div>
      </div>
      <p>射偏</p>
      <div class="bar-item">
        <div class="home">
          <span>{{ detail.home_corner }}</span>
          <span>{{ detail.home_red }}</span>
          <span>{{ detail.home_yellow }}</span>
          <span>{{ detail.home_deflection }}</span>
        </div>
        <div class="bar-progress">
          <a-progress
            :stroke-width="16"
            :percent="deflectionRange"
            :width="500"
            strokeColor="#DF1111"
            :show-text="false"
            define-back-color="#418DFF"
            :show-info="false"
          ></a-progress>
        </div>
        <div class="away">
          <span>{{ detail.away_deflection }}</span>
          <span>{{ detail.away_yellow }}</span>
          <span>{{ detail.away_red }}</span>
          <span>{{ detail.away_corner }}</span>
        </div>
        <!-- away_deflection -->
      </div>
    </div>
    <div class="text-info">
      <p>文字直播</p>
      <ul>
        <li v-for="(e, idx) in event" :key="`${idx}`">
          <span class="time">{{ e.time }}'</span>
          <div
            class="dian"
            :class="{ home: e.is_home == 1, away: e.is_home == 0 }"
          ></div>
          <span class="state" :class="`state-${e.kind}`"></span>
          <span :class="{ red: e.kind == 15 }">{{ e.text }}</span>
          <span
            v-if="[1, 2, 3, 4, 8, 9, 11, 13, 16, 17].includes(Number(e.kind))"
            >({{
              e.is_home == 1 ? detail.home_name_zh : detail.away_name_zh
            }})</span
          >
          <span v-if="[1].includes(Number(e.kind))"
            >({{ e.home_score }} - {{ e.away_score }})</span
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "football-on-time",
  props: {
    detail: Object,
    event: Array,
  },
  computed: {
    attackRange() {
      return (
        (Number(this.detail.away_attack) /
          (Number(this.detail.home_attack) + Number(this.detail.away_attack))) *
          100 || 0
      );
    },
    dangerRange() {
      return (
        (Number(this.detail.away_danger) /
          (Number(this.detail.home_danger) + Number(this.detail.away_danger))) *
          100 || 0
      );
    },
    shootPositiveRange() {
      return (
        (Number(this.detail.home_shoot_positive) /
          (Number(this.detail.home_shoot_positive) +
            Number(this.detail.away_shoot_positive))) *
          100 || 0
      );
    },
    deflectionRange() {
      return (
        (Number(this.detail.home_deflection) /
          (Number(this.detail.home_deflection) +
            Number(this.detail.away_deflection))) *
          100 || 0
      );
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .ant-progress-circle-trail {
  stroke: rgb(255, 0, 0) !important;
}
/deep/ .el-progress-bar__outer {
  background-color: #418dff;
}
/deep/ .ant-progress-outer .ant-progress-inner {
  background-color: #418dff !important;
}
::v-deep .el-progress-circle__track {
  stroke: #df1111;
}
.on-time-wrap {
  padding: 30px 24px;
  .team-info {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 200px;
    .home,
    .away {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      position: relative;
      &:after {
        position: absolute;
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 3px;
      }
      p {
        margin: 0;
      }
      img {
        display: block;
        width: 32px;
        height: 32px;
      }
    }
    .home {
      &:after {
        background: #df1111;
        right: -20px;
        top: 11px;
      }
    }
    .away {
      &:after {
        background: #418dff;
        left: -20px;
        top: 11px;
      }
    }
  }
  .range-info {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    .range-item {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 50px;
      gap: 10px;
    }
  }
  .bar-info {
    widows: 630px;
    margin: 30px auto 0;
    p {
      text-align: center;
      margin-bottom: 12px;
    }
    .bar-item {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      margin-bottom: 30px;
      .bar-progress {
        width: 500px;
      }
      .home,
      .away {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        img {
          width: 12px;
        }
        span {
          text-align: center;
          width: 12px;
        }
      }
    }
  }
  .text-info {
    p {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.9);
      margin-bottom: 20px;
    }
    ul {
      position: relative;
      &::after {
        position: absolute;
        content: "";
        top: 10px;
        bottom: 10px;
        left: 86px;
        width: 1px;
        border-left: 1px dashed #999;
        z-index: 1;
      }
      li {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 20px;
        .time {
          width: 30px;
          text-align: center;
          color: #999;
        }
        .dian {
          width: 14px;
          height: 14px;
          border-radius: 50%;
          background: #999;
          position: relative;
          z-index: 2;
          &.home {
            background: #df1111;
          }
          &.away {
            background: #418dff;
          }
        }
        .red {
          color: #df1111;
        }
        .state {
          width: 20px;
          height: 20px;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          &-1 {
            background-image: url("../../assets/img/score/goal.png");
          }
          &-2 {
            background-image: url("../../assets/img/score/red-card.png");
          }

          &-3 {
            background-image: url("../../assets/img/score/yellow-card.png");
          }

          &-7 {
            background-image: url("../../assets/img/score/penalty.png");
          }

          &-8 {
            background-image: url("../../assets/img/score/own-goal.png");
          }

          &-9 {
            background-image: url("../../assets/img/score/yellow-red.png");
          }

          &-11 {
            background-image: url("../../assets/img/score/exchange.png");
          }
          &-13 {
            background-image: url("../../assets/img/score/loss-penalty.png");
          }
          &-14 {
            background-image: url("../../assets/img/score/goal.png");
          }
          &-15 {
            background-image: url("../../assets/img/score/shaozi.png");
          }
          &-16 {
            background-image: url("../../assets/img/score/corner.png");
          }
          &-17 {
            background-image: url("../../assets/img/score/goal.png");
          }
        }
      }
    }
  }
}
</style>
