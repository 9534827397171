<template>
  <div class="odds-wrap">
    <el-table
      :data="odds"
      style="width: 100%"
      ref="multipleTable"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" label="全选">
      </el-table-column>
      <el-table-column width="200" prop="company_name" label="全部公司">
        <!-- :filters="filters"
           :filter-method="filterHandler" -->
      </el-table-column>
      <template v-if="type == 'eu'">
        <el-table-column prop="e" label="主胜">
          <template slot-scope="{ row }">
            <span :class="row.eclass">{{ row.e }}</span>
          </template>
        </el-table-column>
        <el-table-column label="即时"> </el-table-column>
        <el-table-column prop="f" label="客胜">
          <template slot-scope="{ row }">
            <span :class="row.fclass">{{ row.f }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="b" label="主胜"> </el-table-column>
        <el-table-column label="初始"> </el-table-column>
        <el-table-column prop="c" label="客胜"> </el-table-column>
      </template>
      <template v-else-if="type == 'asia'">
        <el-table-column prop="e" label="主队">
          <template slot-scope="{ row }">
            <span :class="row.eclass">{{ row.e }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="d" label="即时">
          <template slot-scope="{ row }">
            {{ row.d }}
          </template>
        </el-table-column>
        <el-table-column prop="f" label="客队">
          <template slot-scope="{ row }">
            <span :class="row.fclass">{{ row.f }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="b" label="主队"> </el-table-column>
        <el-table-column prop="a" label="初始">
          <template slot-scope="{ row }">
            {{ row.a }}
          </template>
        </el-table-column>
        <el-table-column prop="c" label="客队"> </el-table-column>
      </template>
      <template v-else-if="type == 'bs'">
        <el-table-column prop="e" label="主队">
          <template slot-scope="{ row }">
            <span :class="row.eclass">{{ row.e }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="d" label="即时"> </el-table-column>
        <el-table-column prop="f" label="客队">
          <template slot-scope="{ row }">
            <span :class="row.fclass">{{ row.f }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="b" label="主队"> </el-table-column>
        <el-table-column prop="a" label="初始"> </el-table-column>
        <el-table-column prop="c" label="客队"> </el-table-column>
      </template>
      <el-table-column prop="change_time" label="时间变化" width="120">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "football-odds",
  props: {
    type: String,
    odds: Array,
    instruction: String,
  },
  data() {
    return {};
  },
  watch: {},
  computed: {
    filteredData() {
      console.log(this.odds, 999);
      return this.odds || [];
    },
    filters() {
      const arr = [];
      this.odds.forEach((item) => {
        arr.push({ text: item.company_name, value: item.company_name });
      });
      return arr;
    },
  },

  methods: {
    filterHandler(value, row, column) {
      return row.company_name == value;
    },
    handleSelectionChange(val) {
      this.$emit("filterOdds", val);
    },
  },
  filters: {
    oddsConverter(key) {
      console.info("filters", key);
      var oddName =
        "平手,平/半,半球,半/一,一球,一/球半,球半,球半/两,两球,两/两球半,两球半,两球半/三,三球,三/三球半,三球半,三球半/四球,四球,四/四球半,四球半,四球半/五,五球,五/五球半,五球半,五球半/六,六球,六/六球半,六球半,六球半/七,七球,七/七球半,七球半,七球半/八,八球,八/八球半,八球半,八球半/九,九球,九/九球半,九球半,九球半/十,十球".split(
          ","
        );
      //换算
      if (key) {
        if (key.indexOf("/") > -1) {
          key = key.split("/")[1];
        }
        if (key > 10 || key < -10) return key + "球";
        if (key >= 0) {
          return oddName[parseInt(key * 4)];
        } else if (
          oddName[parseInt(key * 4)] ||
          oddName[Math.abs(parseInt(key * 4))]
        ) {
          return "受" + oddName[Math.abs(parseInt(key * 4))];
        } else {
          return key;
        }
      }
      return "";
    },
  },
};
</script>

<style lang="less" scoped>
.up {
  color: #df1111;
}
.up::before {
  content: "";
  width: 20px;
  height: 20px;
  background: url("~@/assets/img/score/odds-up.png");
  position: absolute;
  top: 13px;
  left: -10px;
}
.down {
  color: #28c927;
}
.down::before {
  content: "";
  width: 20px;
  height: 20px;
  background: url("~@/assets/img/score/odds-down.png");
  position: absolute;
  top: 13px;
  left: -10px;
}

/deep/ .el-table {
  border-radius: 10px;
}
/deep/ .el-table-column--selection .cell {
  padding-left: 10px;
}
/deep/ .el-table__header-wrapper {
  th.el-table__cell {
    background-color: #efefef;
    text-align: center;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.4);
  }
}

/deep/ .el-table__body-wrapper {
  td.el-table__cell {
    background-color: #f8faff;
    text-align: center;
    font-size: 14px;
  }
}
/deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #ffcd00;
  border-color: #ffcd00;
}
/deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #ffcd00;
  border-color: #ffcd00;
}
/deep/ .el-table__column-filter-trigger {
  position: absolute;
  right: 0;
  top: -2px;
}
/deep/ .el-table__column-filter-trigger i {
  font-size: 23px;
}
</style>

<style lang="less"></style>
