<template>
    <div class="chat" @click="visible = false">
        <div class="scrollview" ref="messages" @scroll="onScroll">
            <div class="messages">
                <div v-for="msg in computedMessages" :key="msg.mid">
                    <div class="datetime" v-if="msg.type == 'datetime'">
                        <div class="text">{{ msg.text }}</div>
                    </div>
                    <div class="datetime" v-else-if="msg.sub_type == 'notice'">
                        <div class="text">{{ msg.content }}</div>
                    </div>
                    <div :class="['message', msg.from == temporaryChat.uid ? 'message-left' : 'message-right']" v-else
                        :id="`key-${msg.mid}`">
                        <!-- <div v-if="msg.from == uid">
                            <img class="avatar" :src="msg.from_avatar" />
                        </div> -->
                        <div class="message-content">
                            <div class="msg-txt" v-if="msg.content_type == 'txt'">
                                <div class="text">
                                    <div v-html="msg.formatContent"></div>
                                </div>
                            </div>
                            <div v-else-if="msg.content_type == 'img'" @click="previewImage(msg.content)">
                                <img class="msg-img" :src="msg.content" mode="widthFix" />
                            </div>
                            <div v-else-if="msg.content_type == 'video'" @click="previewVideo(msg.content)">
                                <img class="msg-video" :src="msg.remark" mode="aspectFit" />
                            </div>
                            <div class="msg-txt" v-else>
                                <div class="text">
                                    <span>【消息不支持，请进入手机端查看】</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="bottom1"></div>
                <div id="bottom2"></div>
            </div>
        </div>
        <div :class="['more', visible ? 'open' : '']" @click.stop>
            <div class="editor">
                <!-- <div class="more" @click="visible = !visible"></div> -->
                <input class="input" v-model="text" placeholder="请输入" confirm-type="send" @keydown.enter="onSend"
                    @focus="onFocus" />
                <div class="button" @click="onSend">发送</div>
            </div>
        </div>
        <div class="modal" v-if="modal.src">
            <div class="close" @click="modal.src = ''"></div>
            <video class="video" x5-video-player-type='h5-page' :src="modal.src" controls :show-fullscreen-btn="false"
                :autoplay="true" v-if="/\.mp4/i.test(modal.src)"></video>
            <img class="img" :src="modal.src" v-else />
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import moment from 'moment';
// import 'moment/locale/zh-cn'
// var relativeTime = require('moment/plugin/relativeTime')
// moment.extend(relativeTime)
// moment.locale('zh-cn')

import { EMOTICONS } from '@/utils/constants.js';

let timer = null

export default {
    name: 'wsChat',
    // components: { wsImage },
    props: {
        uid: [String, Number],
        avatar: String,
        token: String,
    },
    data() {
        return {
            init: false,
            messages: [],
            text: '',
            last_mid: '',
            visible: false,
            triggered: false,
            loaded: false, // 全部加载完成

            modal: {
                src: ""
            }
        };
    },
    computed: {
        ...mapState(['temporaryUser', 'temporaryChat', 'temporaryToken']),
        computedMessages() {
            const msgs = this.messages || []
            const m = [];
            let timestamp = 0;
            msgs.forEach((msg) => {
                if (m.some((v) => v.mid == msg.mid)) {
                    return;
                }
                if (timestamp === 0 || timestamp + 180 < msg.timestamp) {
                    let o = {
                        mid: msg.timestamp,
                        type: "datetime",
                        datetime: msg.timestamp
                    }
                    let d = moment(msg.timestamp * 1000)
                    o.text = moment().isAfter(d) ? d.fromNow() : '刚刚'
                    // o.text = moment(msg.timestamp * 1000).format('MM-DD HH:mm')
                    m.push(o);
                    timestamp = msg.timestamp;
                }
                if (msg.content_type == 'txt') {
                    // msg.formatContent = msg.content
                    msg.formatContent = msg.content.replace(/\[[^\[\]]*?\]/g, function ($1) {
                        if (EMOTICONS.includes($1)) {
                            return `<img class="emotion" style="width:24px;height:24px" src="https://360ba.oss-accelerate.aliyuncs.com/emoticons/${$1}.png" alt="${$1}" />`;
                        }
                        return $1;
                    });
                }
                // msg = this.formatMessageContent(msg)
                m.push(msg);
            });
            return m;
        },
        temporaryHeaders() {
            return { authorization: this.temporaryToken }
        },
        minfo() {
            return this.temporaryUser
        }
    },
    mounted() {
        if (this.init) {
            return
        }
        this.init = true
        this.getMessages()
        this.$bus.$on('ws.message', this.onMessage);
    },
    unmounted() {
        this.$bus.$off('ws.message', this.onMessage);
    },
    methods: {
        onScroll(e) {
            if (e.target.scrollTop < 40) {
                this.onRefresh()
            }
        },
        async onRefresh() {
            if (this._freshing || this.loaded) return;
            this._freshing = true;
            await this.getMessages()
        },
        async getMessages() {
            if (this.temporaryChat.uid) {
                const data = {
                    type: 1,
                    limit: 50,
                    mid: this.last_mid || undefined,
                    source: 'h5'
                }
                if (this.messages.length > 0) {
                    data.mid = this.messages[0].mid
                }
                const res = await this.$api.get(`/get_msg/${this.temporaryChat.uid}`, { params: data, headers: this.temporaryHeaders })
                if (res.code == 200 && res.data && res.data.length > 0) {
                    this.messages = res.data.map(item => {
                        this.last_mid = item.uid
                        item.from = parseInt(item.from)
                        item.from_avatar = this.avatar
                        return item
                    }).concat(this.messages)
                    this.$nextTick(() => {
                        setTimeout(() => {
                            this._freshing = false;
                        }, 1000)
                    })
                } else {
                    this.loaded = true
                }
            }
        },
        onFocus() {
            this.visible = false
        },
        onMessage(res) {
            let { temporaryChat } = this
            if (res.event == `message` && res.channel == `presence-${this.minfo.uid}`) {
                if (parseInt(res.data.from) == temporaryChat.uid || parseInt(res.data.to) == temporaryChat.uid) {
                    if (this.messages.findIndex(v => v.mid == res.data.mid) === -1) {
                        this.messages.push(res.data)
                    }
                }
            } else if ((res.event == `message_froward` || res.event == 'message_forward') && res.channel == `presence-${this.minfo.uid}`) {
                res.data = Array.isArray(res.data) ? res.data : []
                res.data.forEach(msg => {
                    if (parseInt(msg.from) == temporaryChat.uid || parseInt(msg.to) == temporaryChat.uid) {
                        if (this.messages.findIndex(v => v.mid == msg.mid) === -1) {
                            this.messages.push(msg)
                        }
                    }
                })
            } else if (res.event == 'revoke') {
                let v = this.messages.find(v => v.mid == res.data.mid)
                if (v) {
                    Object.assign(v, {
                        sub_type: 'notice',
                        content: '此消息已撤回'
                    })
                }
            }
        },
        scrollBottom() {
            this.$nextTick(() => {
                const el = this.$refs.messages;
                el.scrollTop = el.scrollHeight;
            });
        },
        previewImage(url) {
            this.modal.src = url
        },
        previewVideo(url) {
            this.modal.src = url
        },
        async onSend() {
            if (timer) {
                return this.$message.error('客官,说话太快啦(つД`)');
            }
            const data = {
                to: this.temporaryChat.uid,
                type: 1,
                content: this.text,
                content_type: "txt",
            }
            if (data.content.replace(/\s/g, '') === '') {
                return
            }
            this.text = ''
            const res = await this.$api.post(`/send`, data, { headers: this.temporaryHeaders })
            if (res && res.code == 200) {
                timer = setTimeout(() => {
                    clearTimeout(timer)
                    timer = null
                }, 1000)
            } else {
                this.text = data.content
                this.$message.error(res.msg);
            }
        },
        formatMessageContent(message) {
            let content = message.content
            if (message.content_type == "txt") {
                var ele = document.createElement("span");
                ele.innerHTML = content
                    .replace(/</g, "&lt;")
                    .replace(/>/g, "&gt;");
                content = ele.textContent;
                content = content
                    .replace(/</g, "&lt;")
                    .replace(/>/g, "&gt;")
                    .replace(
                        /(https?|http):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/g,
                        function ($1) {
                            return `<span class="inline-link">${$1}</span> `;
                        }
                    )
                    .replace(/\[[^\[\]]*?\]/g, function ($1) {
                        if (EMOTICONS.includes($1)) {
                            return `<img class="emotion" src="https://01zbb.oss-accelerate.aliyuncs.com/emoticons/${$1}.png" alt="${$1}" />`;
                        }
                        return $1;
                    });

                content = content.replace(/\n/g, "<br>");
            }
            message.content = content
            return message;
        },
    },
    watch: {
        'messages.length'() {
            this.$nextTick(this.scrollBottom);
        }
    }
};
</script>

<style lang="less" scoped>
.chat {
    height: 100%;
    display: flex;
    flex-direction: column;
    background: #EDEDED;

    .scrollview {
        flex: 1;
        min-height: 1px;
        overflow-y: auto;
    }

    .messages {
        // padding: 10px;
        // box-sizing: border-box;
        // /deep/ {
        //     .emotion {
        //         width: 30px;
        //         height: 30px;
        //         vertical-align: middle;
        //     }
        // }

        .datetime {
            display: flex;
            align-items: center;
            justify-content: center;

            .text {
                padding: 4px 0;
                font-size: 12px;
                color: #999999;
                // color: #FDFDFD;
                // background-color: rgba(0, 0, 0, 0.4);
                // padding: 2rpx 10px;
                // border-radius: 4px;
            }
        }

        .message {
            display: flex;
            padding: 4px 20px;

            &-left::after,
            &-right::before {
                content: '';
                flex: 1
            }

            &-content {
                max-width: 80%;
            }

            .emotion {
                width: 24px;
                height: 24px;
            }


            .avatar {
                width: 32px;
                height: 32px;
                border-radius: 50%;
            }

            .msg-txt {
                padding: 4px 6px;
                // margin-left: 16px;
                background-color: #FFFFFF;
                border-radius: 8px;
                font-size: 13px;
                line-height: 24px;
                color: #222222;
                word-break: break-all;
            }

            .msg-img,
            .msg-video {
                border-radius: 8px;
                margin-left: 8px;
                max-width: 140px;
                max-height: 200px;
            }

            .msg-video {
                position: relative;
                width: 140px;
                height: 140px;
                background-color: #222222;

                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 100px;
                    height: 100px;
                    transform: translate(-50%, -50%);
                    background: url('../assets/img/play-circle-fill.png') no-repeat center center;
                    background-size: contain
                }
            }

            .text,
            .text div,
            .text span {
                position: relative;
                z-index: 3;
                user-select: text;
                // min-width: 40px;
            }

            &-left {
                .msg-txt {
                    position: relative;
                    background: #FFFFFF;
                    color: #222222;

                    &::before {
                        content: '';
                        position: absolute;
                        z-index: 0;
                        left: -4px;
                        top: 0;
                        width: 91px;
                        height: 32px;
                        // background: url('../assets/img/message-left.png') no-repeat top left;
                        // background-size: auto 32px;
                    }
                }
            }

            &-right {

                .msg-txt {
                    position: relative;
                    background: linear-gradient(270deg, #353B49 0%, #1E2024 100%);
                    // background: #5c63af;
                    color: #FFFFFF;

                    &::before {
                        content: '';
                        position: absolute;
                        z-index: 0;
                        right: -4px;
                        top: 0;
                        width: 91px;
                        height: 32px;
                        // background: url('../assets/img/message-right.png') no-repeat top right;
                        // background-size: auto 32px;
                    }
                }
            }
        }
    }

    .more {
        &.open .actions {
            display: flex;
        }
    }


    .editor {
        height: 50px;
        display: flex;
        align-items: center;
        background: #F6F6F6;
        padding: 0 12px;
        box-sizing: border-box;

        .input {
            padding-left: 12px;
            flex: 1;
            height: 36px;
            background: #FFFFFF;
            border-radius: 4px;
            border: 0
        }

        .button {
            padding: 0 20px;
            display: flex;
            align-items: center;
            height: 36px;
            background: #ff7a1d;
            color: #ffffff;
            border-radius: 4px;
            margin-left: 8px;
        }

    }

    .modal {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 9999;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, .7);

        .close {
            position: absolute;
            top: 20px;
            right: 20px;
            z-index: 98;
            width: 60px;
            height: 60px;
            background: url('../assets/img/close.png') no-repeat center center;
            background-size: contain;
        }

        .video {
            position: relative;
            z-index: 88;
            width: 80%;
            height: 80%;
            object-fit: contain;
            background-color: #1A2332;
        }
    }
}
</style>